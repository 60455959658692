<template>
  <div>
    <div class="col-12">
      <div class="col-12 cardModule">
        <div class="col-12">
          <p class="title-card-module">{{ modulo.titulo_modulo }}</p>
        </div>
        <div class="row footer-card-module">
          <div class="col-10">
            <p class="text-module">
              {{ modulo.ds_modulo }}
            </p>
          </div>
          <div class="col-2">
            <router-link :to="'/video/' + modulo.nr_sequencial + '/'" class="btn btn-play-novidade" replace>
              <i class="bi bi-box-arrow-right h2"></i>
            </router-link>
          </div>
        </div>
        <div class="progress-bar-container">
          <div class="progress-bar" ref="progressBar"></div>
        </div>
        <p style="color: #05F96F;"><span ref="percentageText">0%</span> - {{ videosAssistidos }} assistido de <span ref="totalVideos">0</span> vídeos</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modulo: Object
  },
  data(){
    return {
      videosAssistidos: 0
    }
  },
  mounted() {
    // Certifica-se de que o DOM foi montado antes de acessar os elementos
    const totalVideos = this.modulo.total_aulas; // Total de vídeos
    const completedVideos = this.modulo.aulas_concluidas; // Vídeos concluídos
    this.updateProgressBar(totalVideos, completedVideos);
  },
  methods: {
    updateProgressBar(totalVideos, completedVideos) {
      const progressBar = this.$refs.progressBar;
      const percentageText = this.$refs.percentageText;
      const totalVideosElement = this.$refs.totalVideos;
      this.videosAssistidos = completedVideos

      // Calcula a porcentagem
      const percentage = totalVideos > 0 ? (completedVideos / totalVideos) * 100 : 0;

      // Atualiza o tamanho da barra e o texto com a porcentagem
      progressBar.style.width = `${percentage}%`;
      percentageText.textContent = `${Math.round(percentage)}%`;
      totalVideosElement.textContent = totalVideos;
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped />
