import axios from "axios";

const apiUri = () => {
  const currentHost = window.location.hostname

  if (currentHost === 'localhost') {
    return 'http://localhost:8000'
  }

  if (currentHost.includes('gclass.ginfo.app.br')) {
    return 'https://apigclass.ginfo.app.br'
  }
}

const user = JSON.parse(localStorage.getItem('auth') || '{}');
let token = user?.token || '';

if (token) {
  // Adiciona o token ao cabeçalho Authorization
  token = `Bearer ${token}`;
}

const client = axios.create({
  baseURL: apiUri(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    Authorization: token ? token : ''
  }
})

export default client